import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import About from "./pages/O-nas";
import Service from "./pages/Service";
import Contact from "./pages/Contact";
import Polityka from "./pages/Polityka";
import RouteScrollToTop from "./elements/RouteScrollToTop";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route exact path='/o-nas' element={<About />} />
        <Route exact path='/oferta' element={<Service />} />
        <Route exact path='/kontakt' element={<Contact />} />
        <Route exact path='/polityka-prywatnosci' element={<Polityka />} />
      </Routes>
      <ScrollToTop smooth color='#297F36' />
    </BrowserRouter>
  );
}

export default App;

import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const AboutOne = React.lazy(() => import("../components/AboutOne"));

const CounterOne = React.lazy(() => import("../components/CounterOne"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const PartnerOne = React.lazy(() => import("../components/PartnerOne"));
const SkillOne = React.lazy(() => import("../components/SkillOne"));
const TeamOne = React.lazy(() => import("../components/TeamOne"));
const About = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          <NavbarTwo />
          {/* About One */}
          <div className='pd-top-160 pd-bottom-120'>
            <AboutOne />
          </div>
          {/* Counter One */}
          <div className='fact-area' style={{ background: "#f9f9f9" }}>
            <CounterOne />
          </div>
          {/* Skill One */}
          <SkillOne />
          {/* Team One */}
          <div className='pd-bottom-80'>
            <TeamOne />
          </div>
          {/* Partner One */}
          <PartnerOne />
          {/* Footer One */}
          <FooterOne />
          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default About;

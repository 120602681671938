import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const BannerOne = React.lazy(() => import("../components/BannerOne"));
const AboutOne = React.lazy(() => import("../components/AboutOne"));
const ContactOne = React.lazy(() => import("../components/ContactOne"));
const CounterOne = React.lazy(() => import("../components/CounterOne"));
const FeatureOne = React.lazy(() => import("../components/FeatureOne"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const ServiceOneAll = React.lazy(() => import("../components/ServiceOneAll"));
const TeamOne = React.lazy(() => import("../components/TeamOne"));

const HomeOne = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          <NavbarTwo />
          <BannerOne />
          <FeatureOne />
          <AboutOne />
          <ServiceOneAll />
          <div className='pd-top-120'>
            <CounterOne />
          </div>         
          <div
            className='call-to-contact-area pd-top-160'
            style={{ background: "#F9F9F9" }}
          >
            <ContactOne />
          </div>
          <div className='pd-bottom-120'>
            <TeamOne />
          </div>          
          <FooterOne />
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default HomeOne;

import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";

const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const Polityka = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          <NavbarTwo />
          <div className='pd-top-120 pd-bottom-120'>
            <div className='container'>
              <div className='contact-area mg-top-120 mb-120 pd-top-120 pd-bottom-120'>
                <div className='row g-0 justify-content-center'>
                  <div className='col-lg-7'>
                    <p>POLITYKA PRYWATNOŚCI I WYKORZYSTANIA PLIKÓW COOKIES</p>
                          <p>Ochrona prywatności użytkowników naszej platformy jest dla nas sprawą najwyższej wagi. Dlatego dokładamy wszelkich starań, aby zabezpieczyć dane osobowe naszych klientów. Niniejsza polityka prywatności w przejrzysty sposób przedstawia oraz wyjaśnia zasady i zakres przetwarzania wszystkich danych osobowych. Zawiera ona także procedurę wykorzystywania ciasteczek cookies. Stosowane są najnowocześniejsze środki techniczne oraz organizacyjne, zapewniające wysoki poziom ochrony przetwarzanych danych osobowych. Polityka prywatności została przygotowana przez firmę LoggSzy Zbigniew Szyszka z siedzibą w Opolu, przy ul. Technologicznej 2 i jest skierowana do klientów korzystających z serwisu loogszy.pl. Obowiązuje ona od 20 sierpnia 2023 roku.
                          </p>
                          <p>
                          DEFINICJE:
                          1. Administrator - podmiot, który samodzielnie ustala cele i sposoby przetwarzania danych osobowych. Administratorem danych osobowych użytkowników serwisu loggszy.pl jest Firma LoggSzy Zbigniew Szyszka, ul. Technologiczna 2, 45-839 Opole o numerze NIP: 7561456304 
                          2. Dane osobowe - informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej,
                          3. RODO - Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE,
                          4. Serwis loggszy.pl – strona internetowa loggszy.pl zawierająca treść statyczną, posiadająca sekcję kontaktową oraz zapamiętująca preferencje odbiorców w celu dostosowania treści do indywidualnych upodobań.
                          5. Polityka - niniejsza polityka prywatności, obowiązująca od 20 sierpnia 2023 r,
                          6. Użytkownik - każda osoba fizyczna odwiedzająca serwis loggszy.pl
                          7. Urządzenie – uznajemy za urządzenie każde elektroniczne urządzenie wraz z oprogramowaniem, dzięki któremu Użytkownik otrzymuje dostęp do serwisu loggszy.pl
                          8. Pliki Cookies – dane informatyczne, pliki tekstowe przechowywane na urządzeniach użytkowników (np. na laptopie, komputerze, tablecie lub telefonie) przesyłane przez strony internetowe
                          </p>
                          <p>
                          KTO JEST ADMINISTRATOREM DANYCH OSOBOWYCH?
                          Administratorem Danych Osobowych wszystkich użytkowników serwisu, będących osobami fizycznymi, w rozumieniu rozporządzenia Parlamentu Europejskiego i Rady (UE) z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO) jest Firma LoggSzy Zbigniew Szyszka, ul. Technologiczna 2, 45-839 Opole o numerze NIP: 7561456304 , będąca administratorem serwisu dostępnego pod adresem: https://loggszy.pl
                          </p>
                          <p>
                          JAK SIĘ SKONTAKTOWAĆ Z ADMINISTRATOREM DANYCH OSOBOWYCH?
                          W wszelkich sprawach dotyczących przetwarzania danych osobowych w ramach korzystania z serwisu loggszy.pl można skontaktować się z administratorem za pomocą adresu mailowego: biuro@loggszy.pl lub telefonicznie pod numerem: +48 693 839 560 lub korespondencyjnie na wskazany adres: LoggSzy Zbigniew Szyszka, ul. Technologiczna 2, 45-839 Opole
                          </p>
                          <p>
                          JAK POZYSKUJEMY TWOJE DANE OSOBOWE?
                          Twoje dane osobowe otrzymaliśmy od Ciebie podczas wejścia do serwisu pod adresem loggszy.pl lub w związku z dokonywanymi przez ciebie aktualizacjami.
                          </p>
                          <p>
                          JAKI RODZAJ TWOICH DANYCH PRZETWARZAMY?
                          Administrator przetwarza Twoje następująca dane osobowe, których podanie jest niezbędne do:
                          a. Wypełnienia formularza kontaktowego: imię, nazwisko, numer telefonu, adres e-mail,
                          b. W związku z wykorzystywaniem plików cookies: adres IP, nazwa domeny, typ przeglądarki, rodzaj systemu operacyjnego oraz dane o ścieżce nawigacji Użytkownika i o czasie jego pozostawania na określonych podstronach.
                          </p>
                          <p>
                          W JAKIM CELU PRZETWARZAMY TWOJE DANE OSOBOWE?
                          Dane osobowe Użytkowników przetwarzane są w celach:
                          Możliwości pełnego korzystania z serwisu loggszy.pl
                          Komunikowania się z Tobą. Używamy Twoich danych osobowych do komunikowania się z Tobą w związku z świadczącymi usługami za pośrednictwem różnych kanałów np. mailowo, czy przez formularz kontaktowy,
                          Zapewnienia bezpieczeństwa usług. Używamy Twoich danych osobowych do zapewnienia bezpieczeństwa usług, które świadczymy drogą elektroniczną,
                          Zapobiegania oszustwom. Używamy danych osobowych w celu zapobiegania oszustwom i nadużyciom oraz ich wykrywania w celu ochrony bezpieczeństwa naszych Użytkowników,
                          Przestrzegania zobowiązań prawnych. Zbieramy i wykorzystujemy Twoje dane, aby zachować zgodność z prawem,
                          Zalecenia i personalizacji. Twoje dane osobowe są przetwarzane w celu polecania produktów i usług, które mogą Cię zainteresować. Dopasowujemy reklamy, oferty oraz inne treści zgodnie z uprzednio przeglądanymi treściami,
                          Marketingowych. Twoje dane są przetwarzane w celu prowadzenia działań związanych z marketingiem.
                          </p>
                          <p>
                          NA JAKIEJ PODSTAWIE PRZETWARZAMY TWOJE DANE?
                          Zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE przetwarzamy Twoje dane:
                          1. Gdy przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego (art. 6 ust 1 lit. c RODO). Administrator przetwarza Twoje dane osobowe m.in na podstawie: 1. przepisów prawa telekomunikacyjnego, 2. przepisów świadczenia usług drogą elektroniczną
                          2. Gdy przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora (art. 6 ust. 1 lit. f RODO),
                          3. Gdy przetwarzanie odbywa się na podstawie wyrażonej przez Ciebie zgody (art. 6 ust. 1 lit. a RODO).
                          Zgoda na przetwarzanie Twoich danych osobowych jest dobrowolna. Masz prawo wycofać zgodę w dowolnym momencie. Wycofanie zgody nie wpływa także na zgodność z przetwarzaniem, którego dokonano na podstawie zgody przed jej wycofaniem.
                          </p>
                          <p>
                          CZY UDOSTĘPNIAMY TWOJE DANE OSOBOWE?
                          Informacje o naszych Użytkownikach są ważną częścią naszej działalności i nie zajmujemy się sprzedażą danych osobowych naszych Użytkowników innym osobom. Twoje dane osobowe udostępniane są podmiotom zależnym od administratora. Przestrzegają oni równie wysokiego standardu dbałości o bezpieczeństwo Twoich danych osobowych. Udostępnianie odbywa się w ściśle określonych celach i w sposób opisany poniżej:
                          Udostępniamy Twoje dane osobowe organom władzy publicznej oraz podmiotom realizującym zadania publiczne lub działające na zlecenie organów władzy publicznej, wyłącznie w zakresie i w celach, które wynikają z przepisów prawa,
                          Udostępniamy Twoje dane osobowe podmiotom współpracującym z administratorem, którzy na podstawie stosownych umów przetwarzają dane osobowe,
                          Udostępniamy Twoje dane osobowe podmiotom realizującym zadania administratora m. in. dostawca oprogramowania,
                          Udostępniamy stronom trzecim informacje statystyczne o naszych użytkownikach; osoby trzecie nie mają możliwości identyfikacji żadnego konkretnego użytkownika na podstawie tych informacji
                          </p>
                          <p>
                          CZY PRZEKAZUJEMY TWOJE DANE DO PAŃSTW SPOZA EOG?
                          W głównej mierze podmioty przetwarzające i gromadzące dane osobowe na naszej platformie pochodzą z Polski i krajów Europejskiego Obszaru Gospodarczego (EOG). Poza EOG swoją siedzibę posiada jedynie podmiot współpracujący w zakresie dostarczania usług statystycznych naszej platformy handlowej, który korzysta z narzędzia Google Analytics – Firma Google Inc. posiada swoją siedzibę w USA. Podmiot ten zobowiązany jest do przestrzegania zasad ujętych w programie Tarczy Prywatności UE – USA, które dotyczą gromadzenia, wykorzystywania oraz  przechowywania danych osobowych obywateli Unii Europejskiej – w związku z decyzją wykonawczą Komisji (UE) 2016/1250 z dnia 12 lipca 2016 r. w sprawie adekwatności ochrony zapewnianej przez Tarczę Prywatności UE-USA, a także klauzul przyjętych przez Komisję UE.
                          </p>
                          <p>
                          JAK DŁUGO PRZECHOWUJEMY/PRZETWARZAMY TWOJE DANE OSOBOWE?
                          Dane będziemy przechowywać przez okres niezbędny do realizacji powyższych celów; nie krócej niż przez okres wskazany w przepisach o archiwizacji lub innych przepisach prawa. Dokumenty elektroniczne zawierające dane osobowe zachowamy także, jeżeli:
                          Będą one niezbędne do wywiązywania się z obowiązków, które wynikają wprost z przepisów prawa,
                          Posłużą do zwalczania nadużyć i oszustw,
                          Wykorzystamy je w celach statystycznych,
                          Potrzebne są do celów archiwizacyjnych.
                          Twoje dane pozyskane na podstawie udzielonej przez Ciebie zgody w celach marketingowych będziemy przechowywać do momentu wycofania zgody na przetwarzanie danych w celach prowadzenia działań związanych z marketingiem. Jeżeli chcesz wycofać zgodę na działania marketingowe Administratora możesz w każdej chwili tego dokonać.
                          </p>
                          <p>
                          JAKIE MASZ PRAWA WOBEC PRZETWARZANIA DANYCH OSOBOWYCH?
                          Przetwarzanie Twoich danych osobowych wiąże się z koniecznością respektowania przysługujących Ci praw dotyczących ochrony Twojej prywatności. Charakter podejmowanych działań decyduje o zakresie przysługujących Ci praw.
                          Sposób realizacji Twoich praw: W celu zapewnienia realizacji Twoich praw skontaktować można się z Administratorem serwisu loggszy.pl wysyłając maila na adres: biuro@loggszy.pl
                          Kontaktując się z wskazaną osobą możesz otrzymać kopię swoich danych, czy zasięgnąć innych informacji na temat ich przetwarzania. W trosce o bezpieczeństwo Twoich danych osobowych zastosowaliśmy wewnętrzne procedury, które z łatwością pozwolą nam wywiązać się z realizacji przysługujących Tobie praw.
                          Jeśli chcesz zrealizować którekolwiek z praw opisanych poniżej skontaktuj się Administratorem pod adresem biuro@loggszy.pl
                          Prawo dostępu do Twoich danych osobowych. Skorzystanie z tego prawa umożliwia przekazanie Tobie informacji, czy przetwarzamy dane osobowe z Tobą związane, a jeżeli tak, przysługuje Ci wtedy prawo do otrzymania kopii danych osobowych. Dodatkowo pozwoli Ci również na zweryfikowanie tego, czy przetwarzamy Twoje dane zgodnie z prawem.
                          Prawo do sprostowania Twoich danych osobowych. Jeżeli zauważysz, że w formularzu kontaktowym podałeś nam nieprawidłowe dane osobowe i są one niekompletne, nieprawdziwe lub nieaktualne, masz możliwość żądania poprawienia wskazanych informacji. Pamiętaj, że realizując Twoje żądanie jesteśmy zobowiązany zweryfikować poprawność przekazanych nam nowych danych osobowych z Tobą związanych.
                          Prawo do ograniczenia przetwarzania Twoich danych osobowych. Pamiętaj, że gdy wniesiesz sprzeciw wobec dalszego przetwarzania, musimy zweryfikować czy w dalszym ciągu mamy nadrzędne i prawnie uzasadnione podstawy do kontynuowania przetwarzania Twoich danych osobowych. Skorzystanie z tego prawa pozwala Ci zażądać ograniczenia dalszego przetwarzania Twoich danych osobowych w następujących przypadkach:
                          Gdy kwestionujesz prawidłowość swoich danych osobowych,
                          Gdy okaże się, że nasze przetwarzanie nie jest zgodne z obowiązującymi przepisami prawa,
                          Gdy Twoje dane są Ci potrzebne do ustalenia, dochodzenia lub obrony roszczeń.
                          Prawo do usunięcia Twoich danych osobowych. Mamy obowiązek niezwłocznie usunąć Twoje dane osobowe:
                          Gdy Twoje dane nie są już niezbędne do realizacji celów dla których zostały zebrane lub w inny sposób przetwarzane,
                          Gdy zrealizowałeś przysługujące Ci prawo do sprzeciwu wobec przetwarzania danych,
                          Gdy cofnąłeś zgodę na podstawie której przetwarzaliśmy Twoje dane osobowe,
                          Gdy przetwarzaliśmy Twoje dane niezgodnie z prawem lub jesteśmy zobowiązani do usunięcia Twoich danych osobowych w celu wywiązania się z obowiązku narzuconego przez obowiązujące przepisy prawa.
                          Pamiętaj, że nie zawsze jesteśmy zobowiązani usunąć Twoje dane, w szczególności gdy przetwarzanie ich jest konieczne aby wypełnić obowiązek prawny.
                          Prawo do sprzeciwu wobec przetwarzania Twoich danych osobowych jest odpowiednie wtedy, kiedy uznasz, że przetwarzanie wpływa na Twoje prawa lub wolność. Prawo do sprzeciwu możesz uzasadnić związaną z Twoją osobą szczególną sytuacją z której wynika, że przetwarzanie Twoich danych osobowych łamie Twoje prawa lub wolność. Prawo do sprzeciwu również możesz wykorzystać jeżeli przetwarzamy Twoje dane w celach marketingu bezpośredniego. W niektórych szczególnych sytuacjach możemy wykazać, że posiadamy prawnie uzasadnione podstawy do przetwarzania danych osobowych, które są nadrzędne wobec Twoich praw i wolności. Głównie w przypadku zapewnienia bezpieczeństwa serwisu oraz przeciwdziałania oszustwom. W wskazanych przypadkach prawo to nie łączy się z prawem do usunięcia danych.
                          Prawo do przenoszenia Twoich danych osobowych. Jeżeli chcesz otrzymać osobiście lub dostarczyć wskazanej przez Ciebie osobie trzeciej Twoje dane osobowe w powszechnym formacie, nadających się do odczytu maszynowego możesz skorzystać z prawa do przeniesienia Twoich danych. Pamiętaj, że prawo przysługuje Ci tylko w oparciu o dane przetwarzane na podstawie zgody lub przesłanki do wykonania umowy, którą z nami zawarłeś, a samo przetwarzanie odbywa się w sposób zautomatyzowany w systemach informatycznych.
                          Prawo do wniesienia skargi. Pamiętaj także, że masz prawo wnieść skargę w związku z przetwarzaniem przez nas Twoich danych osobowych do organu nadzorczego, którym jest Prezes Urzędu Ochrony Danych Osobowych (adres: Prezes Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa). Pomimo tego że masz prawo do skargi będziemy bardzo wdzięczni, jeżeli przed wniesieniem skargi, dasz nam szansę zająć się Twoją sprawą i wątpliwościami związanymi z przetwarzaniem przez nas Twoich danych osobowych. Z tego powodu bardzo prosimy o kontakt z naszą osobą odpowiedzialną za bezpieczeństwo informacji.
                          Dokładamy starań, aby odpowiedzieć na wszystkie prawnie uzasadnione żądania w jak najszybszym czasie. Jeżeli Twoje żądanie jest szczególnie skomplikowane lub złożyłeś kilka żądań ich rozpoznanie może zająć nam dłużej niż jest to standardowo przewidziane. W takim wypadku poinformujemy Cię o wydłużeniu terminu i zapewnimy Ci bieżące informacje dotyczące realizacji Twojego żądania.
                          </p>
                          <p>
                          JAK BEZPIECZNE SĄ INFORMACJE O MNIE?
                          Staramy się o jak najwyższy poziom bezpieczeństwa użytkowników serwisu loggszy.pl, dlatego nieustannie pracujemy nad zabezpieczeniem oraz ochroną Twoich danych osobowych podczas transmisji za pomocą specjalistycznych protokołów szyfrowania oraz dedykowanego oprogramowania.
                          Na bieżąco organizujemy, utrzymujemy i aktualizujemy fizyczne, elektroniczne oaz proceduralne zabezpieczenia danych osobowych związane z pozyskiwaniem, przechowywaniem oraz ujawnieniem danych osobowych klientów naszego serwisu. Wdrożone zabezpieczenia dają Tobie najwyższy poziom poczucia bezpieczeństwa Twoich danych osobowych.
                          Nasz serwis gwarantuje funkcje bezpieczeństwa chroniące Państwa dane przez nieautoryzowanym dostępem i utratą danych. Dbamy o regularność tworzenia kopii zapasowych baz danych, dlatego nie musisz obawiać się o utratę danych.
                          Wszystkie informacje o Tobie, które przetwarzamy, przechowujemy na odpowiednio zaszyfrowanych i zabezpieczonych serwerach. Administrator regularnie ocenia stopień bezpieczeństwa w ramach swojej sieci oraz dba o monitorowanie wewnętrznych regulacji i procedur zastosowanych po to, aby chronić dane przed wszystkimi możliwymi negatywnymi następstwami związanymi z utratą, kradzieżą, nieautoryzowanym dostępem. Na bieżąco również identyfikujemy możliwe do przewidzenia ryzyka dla bezpieczeństwa całej sieci serwisu, minimalizujemy zagrożenia dla bezpieczeństwa, w tym poprzez dokonywanie regularnych testów. Wszystkie informacje przetwarzane na platformie są szyfrowane przy użyciu technologii SSL.
                          Pamiętaj, że nawet wdrożone najwyższe standardy bezpieczeństwa w oparciu o zastosowane najnowocześniejsze technologie nie dają całkowitego bezpieczeństwa Twoich danych osobowych w momencie udostępnienia informacji za pośrednictwem Internetu lub publicznie dostępnych sieci. Istnieje więc ryzyko, że dostęp do Twoich danych osobowych mogą uzyskać nieuprawnione do tego osoby trzecie.
                          </p>
                          <p>
                          WYKORZYSTYWANIE PLIKÓW COOKIES
                          Co to są pliki Cookies? To nic innego jak wszelkie dane informatyczne, w szczególności pliki tekstowe, które użytkownicy przechowują na swoich urządzeniach końcowych przesyłane przez różne strony internetowe. Cookies pozwalają rozpoznać Twoje urządzenie i odpowiednio wyświetlić stronę internetową dostosowaną do Twoich indywidualnych preferencji. Cookies przeważnie zawierają: nazwę strony internetowej z której pochodzą, czas przechowywania oraz unikalny numer.
                          W jakim celu używamy plików Cookies? Używamy plików Cookies i innych technologii (zwanych łącznie „plikami Cookies”), aby dokonać rozpoznania Twojej przeglądarki lub urządzenia i dowiedzieć się więcej o Twoich zainteresowaniach oraz zapewnić niezbędne funkcje oraz usługi. W szczególności używamy Cookies w celach:
                          Rozpoznania Twojego urządzenia podczas logowania w celu korzystania z usług serwisu loggszy.pl. Dzięki temu możemy przekazywać Ci rekomendacje produktów oraz usług, wyświetlać spersonalizowane treści oraz zapewnić Ci inne dostosowane funkcje i usługi,
                          Śledzenia określonych preferencji,
                          Zapobiegania nieuczciwej działalności,
                          Poprawy bezpieczeństwa,
                          Raportowania (pozwoli nam to mierzyć i analizować wydajność naszych usług).
                          Jakich plików Cookies używamy? W ramach platformy stosujemy dwa zasadnicze rodzaje plików Cookies: „sesyjne” tzw. session cookies oraz „stałe” tzw. persisent cookies. Sesyjne Cookies są plikami tymczasowymi, które przechowywane są na Twoim urządzeniu do czasu wylogowania się, opuszczenia Pllatformy lub wyłączenia przeglądarki internetowej. Stałe Cookies są przechowywane na Twoim urządzeniu przez określony czas w parametrach plików Cookies lub do czasu ich usunięcia przez Ciebie. W ramach serwisu stosowane są następujące rodzaje plików Cookies:
                          Niezbędne – umożliwiają korzystanie z usług dostępnych w ramach serwisu loggszy.pl np. pliki Cookies uwierzytelniające – wykorzystywane do usług wymagających uwierzytelnienia w ramach serwisu,
                          Wydajnościowe – umożliwiają zbieranie wszelkich informacji o metodzie korzystania z serwisu,
                          Funkcjonalne – umożliwiają zapamiętanie wybranych przez Ciebie konfiguracji oraz personalizację Twojego interfejsu, np. w zakresie wybranego języka lub regionu, rozmiaru czcionki, wyglądu strony internetowej, itp.,
                          Służące do zapewnienia bezpieczeństwa – wykorzystywane są do wykrywania nadużyć w zakresie uwierzytelnienia w ramach Platformy,
                          Jakie zasady bezpieczeństwa powinien zastosować? Twoje urządzenie w serwisie loggszy.pl powinno być wyposażone w program antywirusowy z aktualną bazą definicji wirusów, aktualną i bezpieczną wersją przeglądarki internetowej i włączoną zaporą sieciową „firewall”. Zalecane jest również włączenie w przeglądarce internetowej filtrów antyphishingowych, które sprawdzają, czy strona internetowa jest autentyczna i nie służy wyłudzaniu informacji. Należy również uważać jakie załączniki otwieramy i w jakie odnośniki klikamy w wiadomościach mailowych. Użytkownik powinien korzystać tylko z zaufanych sieci bezprzewodowych.
                          Czy pliki Cookies zawierają dane osobowe? Wprawdzie pliki Cookies nie zawierają oraz nie stanowią danych osobowych, jednakże niektóre informacje przechowywane w plikach Cookies np. preferencje, mogą być traktowane jako dane osobowe. Dane osobowe pozyskiwane przy użyciu plików Cookies mogą być przetwarzane, jedynie w celu wykonywania określonych funkcji na rzecz Użytkownika w sposób przedstawiony powyżej. Dane takie są szyfrowane w sposób uniemożliwiający dostęp do nich osobom nieuprawnionym.
                          Jak zapewniamy bezpieczeństwo? Dla zachowania bezpieczeństwa przetwarzania Twoich danych osobowych używamy mechanizmów składowania i odczytu Cookies, które nie pozwalają na pobieranie żadnych poufnych informacji z Twojego Urządzenia. Przeniesienie z naszych serwerów jakichkolwiek wirusów, koni trojańskich i innych robaków na Twoje Urządzenie jest niemożliwe.
                          Usuwanie plików Cookies i zmiana preferencji. Uprawnienia serwisu loggszy.pl do przechowywania i uzyskiwania plików Cookies wynika z wyrażonej przez Ciebie zgody. Zgoda ta wyrażona jest podczas dokonywania konfiguracji przeglądarki internetowej lub podczas wejścia do serwisu loggszy.pl Masz możliwość konfigurowania w każdej chwili w dowolny sposób swoich ustawień oraz określenia warunków przechowywania plików Cookies. Standardowe oprogramowanie służące do przeglądania stron internetowych domyślnie dopuszcza umieszczenie plików Cookies na urządzeniu końcowym. Możesz w każdej chwili samodzielnie zablokować automatyczną obsługę plików Cookies w ustawieniach przeglądarki. Poniżej dostępne są odnośniki do stron internetowych różnych przeglądarek z dokładnymi instrukcjami dotyczącymi Cookies, w tym sposoby ich usuwania:
                          </p>
                          <p>
                          Google Chrome - https://support.google.com/chrome/answer/95647?hl=en
                          Mozilla Firefox - https://support.mozilla.org/pl/kb/usuwanie-ciasteczek
                          Opera - https://help.opera.com/pl/latest/web-preferences/#cookies
                          Edge - https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history
                          Safari - https://support.apple.com/pl-pl/guide/safari/sfri11471/mac
                          Internet Explorer - http://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies#ie=ie-11
                          Linkedin- https://pl.linkedin.com/legal/privacy-policy
                          Home.pl- https://home.pl/rodo
                          </p>
                          <p>
                          Jeżeli wyłączysz wszystkie pliki Cookies w przeglądarce, ani my, ani osoby trzecie, nie przekażemy plików Cookies do przeglądarki. Jeżeli to zrobisz, konieczne może być ręczne dostosowanie niektórych preferencji każdorazowo odwiedzając serwis, a niektóre funkcje i usługi mogą nie działać.
                          Aby skorzystać do przysługującego Ci prawa do sprzeciwu, które przyznaje RODO konieczne jest wylogowanie się ze wszystkich urządzeń i usunięcie z nich plików Cookies. Proces aktualizacji może potrwać nawet do 48 godzin.
                          </p>
                          <p>
                          ZMIANA POLITYKI PRYWATNOŚCI
                          Polityka Prywatności może być przez nas zmieniana lub aktualizowana w szczególności w przypadku, gdy potrzeba lub obowiązek wprowadzenia zmian wynika ze zmiany przepisów prawa. Wszelkie istotne zmiany zostaną zamieszczone na tej stronie, a dodatkowo poinformujemy Cię o nich za pośrednictwem wiadomości e-mail lub za pośrednictwem informacji w naszym serwisie.
                          </p>
                          <p>
                          POSTANOWIENIA KOŃCOWE
                          Dodatkowe informacje na temat przetwarzania Twoich danych osobowych, w tym odpowiedzi na pytania dotyczące całego zakresu Polityki Prywatności, otrzymasz kontaktując się naszą osobą odpowiedzialną za bezpieczeństwo informacji pod adresem mailowym biuro@loggszy.pl lub listownie pod adresem: LoggSzy Zbigniew Szyszka, ul. Technologiczna 2, 45-839 Opole 
                          </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <FooterOne />
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default Polityka;
